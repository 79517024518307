import React from 'react';

const Logo = ({ color, classes }) => {
    const fill = color ? color : 'currentColor';
    const classNames = `${(classes) ? classes + ' ' : ''}logo`;

    return (
        <svg className={classNames}  viewBox="0 0 43 52" xmlns="http://www.w3.org/2000/svg"><path d="M5.304 12H.296V.576h4.592c2.672 0 4.128 1.072 4.128 3.12 0 1.008-.8 2.112-1.728 2.384 1.248.336 2.192 1.344 2.192 2.672C9.48 10.896 7.944 12 5.304 12zM2.376 5.408h2.496c1.424 0 2.096-.64 2.096-1.632 0-1.04-.688-1.632-2.08-1.632H2.376v3.264zm0 1.52v3.504h2.768c1.472 0 2.24-.656 2.24-1.76 0-1.072-.784-1.744-2.256-1.744H2.376zm23.672.464c0 3.12-1.792 4.8-4.88 4.8-3.072 0-4.88-1.68-4.88-4.832V.576h2.128v6.8c0 2 .912 3.12 2.752 3.12 1.84 0 2.768-1.12 2.768-3.12v-6.8h2.112v6.816zM35.576 12h-2.08V.576h4.896c2.672 0 4.24 1.248 4.24 3.584 0 1.776-1.136 2.832-2.432 3.248L43.064 12h-2.32l-2.608-4.272h-2.56V12zm0-5.936h2.576c1.584 0 2.384-.656 2.384-1.904 0-1.248-.8-1.904-2.384-1.904h-2.576v3.808zM8.568 32H.296V20.576h8.208v1.68H2.376v3.184H7.8v1.68H2.376v3.2h6.192V32zm15.512 0l-1.072-2.8h-5.152L16.768 32h-2.08l4.592-11.424h2.384L26.256 32H24.08zm-3.648-9.376l-1.92 4.928h3.856l-1.888-4.928h-.048zm21.56 4.768c0 3.12-1.792 4.8-4.88 4.8-3.072 0-4.88-1.68-4.88-4.832v-6.784h2.128v6.8c0 2 .912 3.12 2.752 3.12 1.84 0 2.768-1.12 2.768-3.12v-6.8h2.112v6.816zM7.08 52H5.544L2.28 43.024h-.032V52H.296V40.576h3.008l3.072 8.592h.032l3.072-8.592h2.912V52H10.36v-8.976h-.032L7.08 52z" fill={fill} fillRule="evenodd"/></svg>
    );
};

export default Logo;